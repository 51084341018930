'use strict';

(function ($, cssua) {
	var MARY = void 0;
	var siteName = 'mary';
	var clickEvent = cssua.ua.desktop ? 'click' : 'tap';
	var a = 'is-active';
	var c = 'is-current';
	var f = 'is-fixed';
	var h = 'is-hide';
	var v = 'is-visible';
	var orientation = cssua.ua.mobile === 'iphone' ? 'orientationchange' : 'resize orientationchange';

	MARY = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			},
			isSP: null,
			pos: {
				x: null,
				y: null
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test.' + siteName + '.co.jp|copre.jp|localhost|192.168.0.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		spMenu: function spMenu() {
			var $menu = $('.js-menuToggle');
			var $headerWrap = $('.header-wrap');
			var $headerWrapBg = $('.header-wrap-bg');
			var $searchWrap = $('.header-searchWrap');
			var $html = $('html');
			var $body = $('body');

			$menu.on(clickEvent, function () {
				if ($headerWrap.hasClass(v)) {
					MARY.controlNoScrollClass('remove');

					$menu.removeClass(v);
					$headerWrap.removeClass(v);
					$headerWrapBg.removeClass(v);

					if ($('.header_en-button').length) {
						$('.header_en-button').removeClass(v);
					}
				} else {
					if ($searchWrap.hasClass(v)) {
						$('.mod-overwrap').removeClass(v);
						$searchWrap.removeClass(v);

						MARY.controlNoScrollClass('remove');
					}
					MARY.controlNoScrollClass('add');
					$menu.addClass(v);
					$headerWrap.addClass(v);
					$headerWrapBg.addClass(v);

					if ($('.header_en-button').length) {
						$('.header_en-button').addClass(v);
					}

					if ($('.js-language_sp').length) {
						if ($('.js-language_sp').hasClass(v)) {
							$('.js-language_sp').removeClass(v);
							$('.js-language_sp_block').removeClass(v);
						}
					}
				}
			});

			//多言語ページ
			if ($('.js-language_sp').length) {
				var $btn = $('.js-language_sp');
				var $block = $('.js-language_sp_block');

				$btn.on(clickEvent, function () {
					if ($btn.hasClass(v)) {
						MARY.controlNoScrollClass('remove');

						$btn.removeClass(v);
						$block.removeClass(v);
					} else {
						MARY.controlNoScrollClass('add');

						$btn.addClass(v);
						$block.addClass(v);

						if ($headerWrap.hasClass(v)) {
							$menu.removeClass(v);
							$headerWrap.removeClass(v);
							$headerWrapBg.removeClass(v);

							if ($('.header_en-button').length) {
								$('.header_en-button').removeClass(v);
							}
						}
					}
				});
			}
		},
		headerMenuToggle: function headerMenuToggle() {
			var easeSpeed = 600;
			var toggle = '.js-headerToggle';
			var block = '.js-headerToggleBlock';
			MARY.va.isSP = cssua.ua.mobile && this.va.window.width <= this.va.device.sp;
			var mouseEvent = MARY.va.isSP ? 'tap' : 'click';
			$(block).addClass(h);

			$('body').on(mouseEvent, toggle, function (ev) {
				var $this = $(this);
				var $next = $this.next(block);

				if (!$next.length) return;

				toggleClassMethod($this, $next, true);

				ev.preventDefault();
				ev.stopPropagation();
			});
			$('html').on(clickEvent, function (ev) {
				var isTarget = $(ev.target).closest(block).length;
				var isClose = $(ev.target).closest('.no-close').length;

				$(toggle).each(function () {
					var $this = $(this);
					var $next = $this.next(block);

					if (!isClose && !isTarget && $this.hasClass(v)) {
						toggleClassMethod($this, $next);
					}
				});
			});

			function toggleClassMethod($this, $next, _mouseEvent) {
				var $headerWrap = $this.closest('.header-wrap');
				var nextHeight;
				var add = function add() {
					if ($headerWrap.length) $headerWrap.addClass(f);
					$this.addClass(v);
					$next.removeClass(h);
					nextHeight = 0;

					$next.find('> *').each(function () {
						nextHeight += Math.floor($(this).outerHeight(true));
					});

					$next.stop().css('max-height', nextHeight + 'px');
				};
				var remove = function remove() {
					if ($headerWrap.length) $headerWrap.removeClass(f);
					$this.removeClass(v);
					$next.stop().css('max-height', 0).delay(easeSpeed).queue(function () {
						$(this).addClass(h).dequeue();
					});
				};

				if (_mouseEvent && !MARY.va.isSP) {
					if ($next.hasClass(h) && !$this.hasClass(v)) {
						add();
					} else if (!$next.hasClass(h) && $this.hasClass(v)) {
						remove();
					}
				} else if (MARY.va.isSP || !_mouseEvent) {
					if ($next.hasClass(h) && !$this.hasClass(v)) {
						add();
					} else if (!$next.hasClass(h) && $this.hasClass(v)) {
						remove();
					}
				}
			}
		},
		autoMovie: function autoMovie() {
			var autoMoviePlay = document.querySelectorAll('.js-autoMovie');
			if (!autoMoviePlay.length) return;

			var video = document.getElementById('video');
			var videoW = $(window).width();
			var videoX = 1024;
			videoPlay(videoW, videoX);

			$(window).on(orientation, function () {
				var oriW = $(window).width();
				var oriX = 1024;
				videoPlay(oriW, oriX);
			});

			function videoPlay(_videoW, _videoX) {
				if (_videoW <= _videoX) {
					video.autoplay = false;
					video.loop = false;
					video.controls = true;
				} else {
					video.autoplay = true;
					video.loop = true;
				}
			}
		},
		nextToggle: function nextToggle() {
			$('body').on(clickEvent, '.js-toggle', function (ev) {
				var $this = $(this);
				var $next = $this.next('.js-toggleBlock');

				if ($next.length > 0) {
					$this.toggleClass(v);
					$next.toggleClass(v);
				}
				ev.stopPropagation();
			});
			$('html').on(clickEvent, function (ev) {
				var isTarget = $(ev.target).closest('.js-toggleBlock').length;
				var isClose = $(ev.target).closest('.no-close').length;

				if (isClose === 0 && isTarget === 0 && $('.js-toggle').hasClass(v)) {
					$('.js-toggle').removeClass(v);
					$('.js-toggleBlock').removeClass(v);
				}
			});
		},
		accordion: function accordion() {
			var details = document.querySelectorAll('.js-accordion');

			details.forEach(function (ele) {
				var summary = ele.querySelector('summary');
				var wrapper = ele.querySelector('.mod-accordion-wrapper');
				var plus = ele.querySelector('.mod-accordion-plus');
				summary.addEventListener('click', function (e) {
					e.preventDefault();
					if (ele.open) {
						var close = wrapper.animate({
							opacity: [1, 0],
							height: [wrapper.offsetHeight + 'px', 0]
						}, {
							duration: 300,
							easing: 'ease'
						});
						plus.animate({ rotate: ['90deg', '0deg'] }, {
							duration: 300,
							pseudoElement: "::after",
							easing: 'ease',
							fill: 'forwards'
						});
						close.onfinish = function () {
							ele.removeAttribute('open');
						};
					} else {
						ele.setAttribute('open', 'true');
						wrapper.animate({
							opacity: [0, 1],
							height: [0, wrapper.offsetHeight + 'px']
						}, {
							duration: 300,
							easing: 'ease'
						});
						plus.animate({ rotate: ['0deg', '90deg'] }, {
							duration: 300,
							pseudoElement: "::after",
							easing: 'ease',
							fill: 'forwards'
						});
					}
				});
			});
		},
		getQuery: function getQuery(_string) {
			var queryTarget = _string ? _string : window.location.href;
			var vars = [];
			var hash = null;
			var hashes = queryTarget.slice(queryTarget.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on(clickEvent, function () {
				var speed = 1200;
				if (/#/.test(this.href)) {
					scrollToObject(this.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var headerH = $('.header').outerHeight(true);
				var space = 0;
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top - headerH - space;

				//トップページのpc版のみ追従ナビの高さを追加
				if ($(window).width() > 979 && $('.index-pcAnchor').length) {
					var topHeaderH = 121;
					var topPos = hash === '#pagetop' ? 0 : $(hash).offset().top - topHeaderH - space;
					$('html,body').stop().animate({ scrollTop: topPos }, _speed, 'easeOutExpo');
				} else {
					$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
				}
			}
		},
		queryScroll: function queryScroll() {
			if (location.search && (location.search.match(/scroll/) || []).length) {
				if (cssua.ua.ie) {
					window.onload = $(function () {
						setTimeout(function () {
							scrollToObject();
						}, 2000);
					});
				} else {
					$(function () {
						setTimeout(function () {
							scrollToObject();
						}, 2000);
					});
				}
			}
			$('a[href*="scroll="]').on(clickEvent, scrollToObject);

			function scrollToObject(ev) {
				var query = MARY.getQuery()['scroll'];
				var anchorSearch = ev ? ev.currentTarget.search : null;
				var path = ev ? ev.currentTarget.pathname : null;

				$('.js-toggle').removeClass(v);
				$('.js-toggleBlock').removeClass(v);

				if (anchorSearch && path !== location.pathname) {
					location.href = path;
				} else if (query && anchorSearch && path === location.pathname || !query && anchorSearch) {
					query = MARY.getQuery(anchorSearch)['scroll'];
					init(query);
					ev.preventDefault();
				} else if (query) {
					init(query);
				} else if (path) {
					location.href = path;
				}
			}
			function init(_query) {
				var speed = 1000;
				var minimalHeight = 66;
				var TopmalHeight = 121;
				var timeout = 10;
				var target = document.getElementById(_query) || document.querySelector('a[name="' + _query + '"]');

				if (target) {
					var pos = $(target).offset().top - minimalHeight;
					setTimeout(function () {
						$(target).find('.js-toggle').addClass(v).end().find('.js-toggleBlock').addClass(v);
					}, timeout);

					if (cssua.ua.mobile) {
						var mbminimalHeight = $('.header').outerHeight();
						var mbpos = $(target).offset().top - mbminimalHeight;
						$('html,body').stop().animate({
							scrollTop: mbpos
						}, speed, 'easeOutExpo');
					} else {
						if ($(window).width() > 979 && $('.index-pcAnchor').length) {
							var Toppos = $(target).offset().top - TopmalHeight;
							$('html,body').stop().animate({
								scrollTop: Toppos
							}, speed, 'easeOutExpo');
						} else {
							$('html,body').stop().animate({
								scrollTop: pos
							}, speed, 'easeOutExpo');
						}
					}
				}
			}
		},
		changeScrollHeader: function changeScrollHeader() {
			var header = document.querySelector('.header');
			var headerH = header.getBoundingClientRect().height;
			var m = 'is-minimal';
			var timer, flag;

			if (MARY.va.isSP) return;

			toggleClass(window.scrollY || window.pageYOffset);

			window.addEventListener('scroll', function () {
				if (timer) clearTimeout(timer);
				toggleClass(window.scrollY || window.pageYOffset);
			}, false);

			function toggleClass(_scrollPos) {
				if (_scrollPos === 0 && flag) {
					header.classList.remove(m);
					flag = false;
				} else if (_scrollPos > headerH && !flag) {
					header.classList.add(m);
					flag = true;
				}
			}
		},
		pagetop: function pagetop() {
			var pagetop = $('.footer-pagetop');
			var footer = void 0;
			if (MARY.va.pathname.indexOf('/ruru/') !== -1) {
				footer = $('.footer');
			} else {
				footer = $('.footer-sub');
			}

			// Pagetop fixed.
			$(window).on('scroll', function () {
				var $this = $(this),
				    thisScroll = $this.scrollTop(),
				    pagetopPos = $this.height() + $(footer).height() + thisScroll,
				    footerPos = $(footer).offset().top + $(footer).height();

				if (thisScroll === 0) {
					$(pagetop).removeClass('is-moved is-fixed').addClass('is-reset');
				} else {
					if (thisScroll > 0 && footerPos > pagetopPos) {
						$(pagetop).removeClass('is-reset is-fixed').addClass('is-moved');
					}
					if (footerPos <= pagetopPos) {
						$(pagetop).removeClass('is-reset is-moved').addClass('is-fixed');
					}
				}
			});
		},
		googleCustomSearch: function googleCustomSearch() {
			var cx = '006266489876715393143:ud_ka58z00i';
			var gcse = document.createElement('script');
			gcse.type = 'text/javascript';
			gcse.async = true;
			gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
			var s = document.getElementsByTagName('script')[0];

			s.parentNode.insertBefore(gcse, s);
		},
		visibleHeaderSearch: function visibleHeaderSearch() {
			MARY.va.isSP = cssua.ua.mobile && this.va.window.width <= this.va.device.sp;
			var $modal = $('<div />');
			var $searchWrap = $('.header-searchWrap');
			$modal.addClass('mod-overwrap');
			$('body').append($modal);

			$('#js-search').on(clickEvent, function () {
				$modal = $('.mod-overwrap');

				if ($searchWrap.hasClass(v)) {
					$modal.removeClass(v);
					$searchWrap.removeClass(v);
					MARY.controlNoScrollClass('remove');
				} else {
					$('.header-wrap').removeClass(v);
					$('.header-wrap-bg').removeClass(v);
					$('.js-menuToggle').removeClass(v);

					$modal.addClass(v);
					$searchWrap.addClass(v);
					MARY.controlNoScrollClass('remove');

					$('html').on(clickEvent + '.search', htmlClickEvent);
				}
			});

			function htmlClickEvent(ev) {
				var $target = $('.header-searchWrap');
				var $closestIcon = $(ev.target).closest('#js-search');
				var $closestTarget = $(ev.target).closest('.header');

				if (!$closestIcon.length && !$closestTarget.length && $target.hasClass(v)) {
					$target.removeClass(v);
					$modal.removeClass(v);
					MARY.controlNoScrollClass('remove');
				}
			}
		},
		controlNoScrollClass: function controlNoScrollClass(_flag) {
			if (_flag === 'remove' && MARY.va.isSP && MARY.va.pos.y !== null) {
				document.documentElement.classList.remove(f);
				document.body.classList.remove(f);
				window.scrollTo(0, MARY.va.pos.y);
			} else if (_flag === 'add' && MARY.va.isSP) {
				MARY.va.pos.y = document.body.scrollTop + document.documentElement.scrollTop;
				document.documentElement.classList.add(f);
				document.body.classList.add(f);
			}
		},
		currentUrlMatch: function currentUrlMatch() {
			var path = location.pathname.replace('index.html', '');
			var anchor = document.querySelectorAll('.js-current');
			if (!anchor.length) return;
			var replacePath = location.protocol + '//' + location.host;

			for (var i = 0, aLen = anchor.length; i < aLen; i++) {
				var item = anchor[i];
				var href;
				if (item.tagName === 'LI') {
					var array = $(item).data('link');
					if (array && array.length > 1) {
						for (var j = 0; j < array.length; j++) {
							if (path === array[j]) item.classList.add(c);
						}
					} else if (array && array.length === 1 && path === array[0]) {
						item.classList.add(c);
					}
				} else {
					href = item.href.replace(replacePath, '');

					if (path === href) item.classList.add(c);
				}
			}
		},
		wordCutting: function wordCutting() {
			var word = document.querySelectorAll('.js-wordcut');
			if (!word.length) return;
			var afterTxt = '...';

			for (var i = 0, wordLen = word.length; i < wordLen; i++) {
				var cutFigure = Number(word[i].dataset.cut);
				var $target = $(word[i]).data('cut-target');
				var textLength = word[i].innerText.length;

				if ($target && $target === 'sp') {
					if (cssua.ua.mobile && $(window).width() < 640) {
						process(textLength, cutFigure, i);
					}
				} else {
					process(textLength, cutFigure, i);
				}
			}

			function process(_textLength, _cutFigure, _i) {
				if (_textLength > _cutFigure) {
					var textTrim = word[_i].innerText.substr(0, _cutFigure);
					word[_i].innerText = textTrim + afterTxt;
				}
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		ieHeaderLoad: function ieHeaderLoad() {
			if (cssua.ua.ie) {
				$('.header').css('display', 'none');
				$('.footer-pagetop').css('display', 'none');
				window.onload = $(function () {
					$('.header').css('display', 'block');
					$('.footer-pagetop').css('display', 'block');
				});
			}
		},
		language: function language() {
			if (location.href.indexOf('translation2.j-server.com') !== -1) {
				$('.js-language-en').css('display', 'none');
			} else {
				$('header olang').css('display', 'none');
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			if (!$(".fancy-header").length) {
				if (!$(".js-lp-header").length) {
					_this.changeScrollHeader();
					_this.spMenu();
					_this.headerMenuToggle();
					_this.ieHeaderLoad();
					_this.easeScroll();
				}
			}
			_this.autoMovie();
			_this.nextToggle();
			_this.accordion();
			_this.queryScroll();
			_this.pagetop();
			_this.googleCustomSearch();
			_this.visibleHeaderSearch();
			_this.currentUrlMatch();
			_this.wordCutting();
			_this.language();
		}
	};

	window.MARY || (window.MARY = {});
	window.MARY = MARY || (MARY = {});

	$(function () {
		return MARY.localDecision() ? MARY.localLoading() : MARY.loadDelayScript();
	});
})(window.jQuery, window.cssua);